<template>
  <div class="NotClothes">
    <!-- 未穿工服-->
    <div class="title">
      <div class="title_days">
        未穿工服<a-tooltip
          placement="right"
          :get-popup-container="getPopupContainer"
          overlayClassName="popover"
        >
          <template slot="title">今日员工未穿工服报警</template>
          <img
            src="../../assets/images/visualize/title_icon.png"
            style="padding: 0"
          />
        </a-tooltip>
      </div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div
      class="swiper-container"
      id="swiper-container-NotClothes"
      v-if="swiper_list.length > 0"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :data-href="sliteItem(item)"
          @click="handleClickSlide(item)"
        >
          <div class="img_info">
            <div class="img">
              <!-- <img :src="item.e_img" alt="" /> -->
              <img :src="pathUrl + item.e_img" alt="" />
            </div>
            <div class="info">
              <div class="name">
                姓名：{{ item.si_eventworker }}
                <!-- <span class="w_post">{{ item.w_post }}</span> -->
                <span :class="item.w_post" id="w_post">{{ item.w_post }}</span>
              </div>
              <div class="ws_name">
                工地：
                {{ item.ws_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty">暂无数据</div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { urlConfig } from "@/config/config";
import {
  GetWsmokeevent,
  GetWclothesevent,
  GetWscleanbadpic,
} from "../../api/adminBoard";
export default {
  name: "NotClothes",
  props: {
    bc_id: {},
    showDrawer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mySwiper: "0",
      swiper_list: [],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      queryParam: { bc_id: "" },
      record: {
        ws_id: "",
        ws_name: "",
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
    // this.initSwiper(); //不能放在created里
  },
  updated() {
    // if (this.mySwiper) {
    //   this.$nextTick(() => {
    //     this.mySwiper.loopDestroy();
    //     this.mySwiper.loopCreate();
    //   });
    // }
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    initSwiper() {
      let vm = this;
      //   this.mySwiper = new Swiper("#swiper-container", {
      vm.mySwiper = new Swiper("#swiper-container-NotClothes", {
        // effect: "coverflow",
        slidesPerView: 3,
        centeredSlides: true,
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        initialSlide: 1,
        direction: "vertical", // 垂直切换
        // slidesPerView: 4, //一次显示4个
        // slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          vm.mySwiper.startAutoplay();
          vm.mySwiper.reLoop();
        },
        on: {
          //   slideChangeTransitionEnd: function () {},
          //   click: function (swiper, event) {},
          // slideChangeTransitionStart: function () {
          //   let active = document
          //     .querySelector(".swiper-slide-active")
          //     .getAttribute("data-swiper-slide-index");
          //   // console.log(active);
          //   // console.log(this.realIndex);
          // },
          click: (swiper) => {
            // console.log("未穿工服swiper", swiper.target);
            let target = swiper.target;
            // 循环判断返回的数据里是否包含swiper.target
            // 获取dom元素的内容  src里的innerText为空
            vm.swiper_list.forEach((el) => {
              if (!target.src && target.innerText.indexOf(el)) {
                // console.log(el.si_eventworker);
                // console.log(target.innerText);
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "AlarmEvent");
                vm.$router.push({
                  path: "/AlarmEvent",
                  query: {
                    e_events: "noclothes",
                    si_eventworker: "1",
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              } else {
                console.log("111", target.innerText);
              }
              // 点击图片显示直播  --改为跳转
              if (target.src && target.src.indexOf(el.e_img) != -1) {
                // console.log(el.ws_id);
                // vm.record = el;
                // // console.log(vm.record);
                // vm.$emit("record", vm.record);
                // vm.showDrawer();
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "AlarmEvent");
                vm.$router.push({
                  path: "/AlarmEvent",
                  query: {
                    e_events: "noclothes",
                    si_eventworker: "1",
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
            });
          },
        },
      });
    },
    // 开启loop模式，拿不到第二轮首页数据
    handleClickSlide(index) {
      // console.log(index);
    },
    sliteItem(item) {
      //   console.log(item);//可以获取所有的数据
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetWclothesevent(params)
        .then((res) => {
          // console.log("未穿工服", res.data);
          this.swiper_list = res.data;
          // console.log(this.swiper_list);
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
  },
};
</script>

<style lang="less" scoped>
.NotClothes {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      // background-color: pink;
      cursor: pointer;
      .img_info {
        display: flex;
        width: 100%;
        height: 100%;
        .img {
          width: 20%;
          height: 100%;

          img {
            width: 100%;
            height: 90%;
          }
        }
        .info {
          height: 100%;
          width: 80%;
          text-align: left;
          color: #ffff;
          .name {
            padding: 10px;
            padding-bottom: 0;
            #w_post {
              background-color: #008080;
              border-radius: 10px;
              padding: 2px 10px;
              // display: block;
            }
          }
          .ws_name {
            padding: 10px;
            //  width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
  }
}
</style>
