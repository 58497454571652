<template>
  <!-- 在建工地 -->
  <div class="Construct">
    <div class="title">
      <div class="title_site">
        <!-- 在建工地 -->
        已绑定工地
        <a-tooltip
          placement="right"
          :get-popup-container="getPopupContainer"
          overlayClassName="popover"
        >
          <template slot="title">
            <!-- 最近7天每天在建工地数量；
            <br />
            在建工地是指：施工中-工序为“开工交底、辅料入场、水电验收、中期验收”状态是工地； -->
            统计截止昨天已绑定设备的工地数量
          </template>
          <img
            src="../../assets/images/visualize/title_icon.png"
            style="padding: 0"
          />
        </a-tooltip>
      </div>
      <div class="title_more" @click="toSiteList">
        <!-- <a href="">查看详情</a> -->
        查看详情
      </div>
    </div>
    <div id="Construct"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetOnworksite, GetBindworksite } from "../../api/adminBoard";
export default {
  name: "Construct",
  props: {
    bc_id: {},
  },
  data() {
    return {
      option: {
        color: "#100C2A",
        tooltip: {
          trigger: "item",
          formatter: "{c}个", //{b}：name; {c}:value
        },
        grid: {
          // x: 20,
          // y: "4%",
          // right: "4%",
          // left: "16%",
          // bottom: "15%", //默认60
          // x: 20,
          y: "18%",
          right: "8%",
          left: "18%",
          bottom: "15%", //默认60
        },
        xAxis: {
          type: "category",
          data: ["A", "B", "C", "D", "E", "F", "G"],
          // name: "(日期)",
          boundaryGap: false, //坐标轴两边不留白
          nameTextStyle: {
            // x轴name的样式调整
            // color: "#000",
            fontSize: 12,
          },
          // nameRotate: 90, // y轴name旋转90度 使其垂直
          nameGap: 5, // x轴name与横坐标轴线的间距
          // 取消横坐标文字太多自动隐藏
          axisLabel: {
            // interval: 0,
            // rotate: 20, //文字倾斜
          },
        },
        yAxis: {
          type: "value",
          name: "工地数",
          nameGap: 10, // x轴name与横坐标轴线的间距
          // min: 0,
          // max: 100,
          position: "left",
          // length: 50,
          splitLine: {
            lineStyle: {
              // 设置背景横线
              color: "#807a7a83",
              // color: "#8381814d",
            },
          },
        },
        series: [
          {
            data: [800, 1000, 900, 900, 1300, 1400, 1411],
            type: "line",
            color: "#386ABF",
            cursor: "default", // 鼠标样式
          },
        ],
      },
      queryData: {
        ws_bind_status: "2", //绑定状态
        ws_status: "0", //工地状态
        d_status: "0", //设备状态
        // 里程碑节点
      },
      queryParam: { bc_id: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    // 点击查看详情跳转到工地列表页面
    toSiteList() {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "./SiteList",
        query: this.queryData,
      });
    },
    async loadData() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("Construct"));
      let xAxis_date = [];
      let sum = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      // await GetOnworksite(params).then((res) => {
      await GetBindworksite(params).then((res) => {
        // console.log(res.data);
        res.data.forEach((element) => {
          if (element.time) {
            let date = this.$Format(element.time, "date");
            sum.push(element.sum);
            xAxis_date.push(date.slice(5));
          }
        });
        // console.log(xAxis_date);
        // this.option.series[0].data.sum = res.data.slice(1).sum;
        this.option.xAxis.data = xAxis_date;
        this.option.series[0].data = sum;
        // console.log(this.option.series[0].data);
        // 设置纵坐标的最大值
        let dataMax = [];
        res.data.forEach((el) => {
          dataMax.push(el.sum * 1);
        });
        function max(prev, next) {
          return Math.max(prev, next);
        }
        // console.log(dataMax.reduce(max));
        let max1 = dataMax.reduce(max) + "";
        // console.log(max1.slice(0, max1.length - 1));
        this.option.yAxis.max = max1.slice(0, max1.length - 1) * 10 + 10;
        //  获取最小值
        // console.log(max1.slice(0, max1.length - 1) * 10 + 10);
        // console.log(Math.min.apply(null, dataMax) - 10);
        // this.option.yAxis.max = Math.max.apply(null, dataMax) + 10;
        this.option.yAxis.min = Math.min.apply(null, dataMax) - 10;
        // console.log(this.option.yAxis.max);
      });
      // 绘制图表
      myChart.setOption(this.option);
      myChart.on("click", function (params) {
        console.log(params);
        console.log(params.dataIndex);
        console.log(params.data);
        // if (params.componentType === "markPoint") {
        //   // 点击到了 markPoint 上

        //   if (params.seriesIndex === 5) {
        //     // 点击到了 index 为 5 的 series 的 markPoint 上。
        //   }
        // } else if (params.componentType === "series") {
        //   if (params.seriesType === "graph") {
        //     if (params.dataType === "edge") {
        //       // 点击到了 graph 的 edge（边）上。
        //     } else {
        //       // 点击到了 graph 的 node（节点）上。
        //     }
        //   }
        // }
      });
    },
  },
};
</script>

<style scoped lang="less">
.Construct {
  height: 100%;
  width: 100%;
  /deep/ div > div > canvas {
    cursor: default !important;
  }
  // display: flex;
  // position: relative;
  // justify-content: flex-end; //右对齐
  .title {
    width: 65%;
    height: 10%;
    // position: absolute;
    color: #66ffff;
    // text-align: right;
    // padding-left: 10px;
    font-size: 18px;
    display: flex;
    margin-left: 35%;
    // align-items: right;
    // justify-content: right;
    // justify-content: flex-end; //右对齐
    justify-content: space-between;
    // background-color: #fff;

    .title_site {
      width: 50%;
      // margin-left: 30%;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .title_more {
      margin-right: 10px;
      // width: 50%;

      color: #1a6fff;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
#Construct {
  height: 90%;
  width: 100%;
  // top: -10%;
  // background-color: #8381814d;
  /deep/ div > div > canvas {
    cursor: default !important;
  }
}
/deep/ .ant-tooltip-inner {
  width: 100% !important;
}
</style>
