<template>
  <div class="v-container">
    <div class="sub-container">
      <!-- 顶部标题 -->
      <div class="v-title">
        <div class="v-title-inner">
          <div class="v-title-left">
            {{ nowTime }}
          </div>
          <div class="v-title-left2">
            <a-select
              label-in-value
              v-model="queryParam"
              style="width: 200px"
              @change="handleChange"
            >
              <a-select-option
                v-for="item in selectOption"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <div class="v-title-center">管理看板</div>
          <!-- <div class="v-title-right" @click="TuiChu()"><span></span>退出</div> -->
        </div>
      </div>
      <!-- 中间数据 -->
      <div class="v-container-data">
        <div class="v-container-inner">
          <div class="container_left">
            <div class="Employee_attendance border">
              <!-- 员工出勤 -->
              <EmployAttendance :bc_id="bc_id" />
            </div>
            <div class="border">
              <!-- 在建工地 -->
              <ConstructSite :bc_id="bc_id" />
            </div>
            <div class="border">
              <!-- 新开工工地 -->
              <NewlySite :bc_id="bc_id" />
            </div>
            <div class="border">
              <!-- 连续无人施工 -->
              <UnmannedDays :bc_id="bc_id" />
            </div>
            <div class="border Unmanned_construction">
              <!-- 累计无人施工 -->
              <GrandtotalDays :bc_id="bc_id" />
            </div>
          </div>
          <div class="container_centre">
            <div class="container_centre_top">
              <div class="border">
                <!-- 整洁度差项目经理 -->
                <BadManager :bc_id="bc_id" />
              </div>
              <div class="border">
                <!-- 在线率低的项目经理 -->
                <OnlinerateManager :bc_id="bc_id" />
              </div>
              <div class="border">
                <!-- 摄像头歪曲 -->
                <CameraDistortion
                  @record="RecordSon"
                  :bc_id="bc_id"
                  :showDrawer="showDrawer"
                />
              </div>
            </div>
            <div class="container_centre_map">
              <Map
                :bc_id="bc_id"
                @record="RecordSon"
                :showDrawer="showDrawer"
              />
            </div>
          </div>
          <div class="container_right">
            <div class="border container_right_photo">
              <!-- 整洁度差工地快照 -->
              <BadPhoto
                @record="RecordSon"
                :bc_id="bc_id"
                :showDrawer="showDrawer"
              />
            </div>
            <div class="border container_right_smoke">
              <!-- 抽烟报警 -->
              <SmokingAlarm
                @record="RecordSon"
                :bc_id="bc_id"
                :showDrawer="showDrawer"
              />
            </div>
            <div class="border container_right_uniform">
              <!-- 未穿工服 -->
              <NotClothes
                @record="RecordSon"
                :bc_id="bc_id"
                :showDrawer="showDrawer"
              />
            </div>
            <div class="border container_right_count">
              <!-- 报警事件统计 -->
              <AlarmStatistics :bc_id="bc_id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="v-container-Smoking">
      <Smoking :bc_id="bc_id" />
    </div>
    <div id="v-container-img">
      <ClockIn :bc_id="bc_id" />
    </div>
    <!-- 直播抽屉 -->
    <a-drawer
      placement="right"
      :width="800"
      :closable="true"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <SitePreview
        :record="record"
        :SiteList_previer_true="true"
        ref="SitePreview"
        @JumpSubpage="JumpSubpage"
        v-if="SitePreview"
      />
      <SiteParticulars
        :record="record"
        v-if="SiteParticulars"
        ref="SiteParticulars"
      />
      <SitePlayback
        :record="record"
        v-if="SitePlayback"
        ref="SitePlayback"
      ></SitePlayback>
    </a-drawer>
  </div>
</template>
<script>
import { GetCompanies } from "@/api/device";
import ConstructSite from "./ConstructSite.vue";
import EmployAttendance from "./EmployAttendance.vue";
import NewlySite from "./NewlySite.vue";
import UnmannedDays from "./UnmannedDays.vue";
import GrandtotalDays from "./GrandtotalDays.vue";
import BadManager from "./BadManager.vue";
import OnlinerateManager from "./OnlinerateManager.vue";
import CameraDistortion from "./CameraDistortion.vue";
import SmokingAlarm from "./SmokingAlarm.vue";
import AlarmStatistics from "./AlarmStatistics.vue";
import Map from "./Map.vue";
import { GetPatrolphoto } from "../../api/patrol";
import { urlConfig } from "@/config/config";
import ClockIn from "./ClockIn.vue";
import Smoking from "./Smoking.vue";
import BadPhoto from "./BadPhoto.vue";
import NotClothes from "./NotClothes.vue";
import SitePreview from "../site/SitePreview.vue";
import SiteParticulars from "../site/SiteParticulars.vue";
import SitePlayback from "../site/SitePlayback.vue";

const orderParam = [
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "device_status",
  "imei",
  "bc_ids",
  "ws_ids",
  "w_gj_ids",
  "w_jl_ids",
  "ws_only",
  "pageNo",
  "pageSize",
];
export default {
  name: "BoardTotal",
  components: {
    EmployAttendance,
    ConstructSite,
    NewlySite,
    UnmannedDays,
    GrandtotalDays,
    BadManager,
    OnlinerateManager,
    CameraDistortion,
    SmokingAlarm,
    AlarmStatistics,
    Map,
    ClockIn,
    Smoking,
    BadPhoto,
    NotClothes,
    SitePreview,
    SiteParticulars,
    SitePlayback,
  },
  props: {
    // record: {
    //   type: Object,
    //   default: {
    //     ws_id: "1",
    //   },
    //   // default: () => {},
    // },
  },
  data() {
    return {
      nowTime: "", // 当前日期时间
      // 分公司下拉菜单
      queryParam: [],
      selectOption: [],
      bc_id: "",
      ws_name: "",
      record: { ws_id: "" },
      ws_id: "",
      visible: false,
      SiteParticulars: false,
      SitePlayback: false,
      SitePreview: false,
    };
  },
  created() {
    this.getTime();
    this.GetCompanies();
    this.GetCompaniesValue();
  },
  mounted() {},
  watch: {
    // 监听bc_id的变化
    bc_id(newVal, oldVal) {
      console.log("bc_id", newVal);
    },
    ws_id(NValue, OValue) {
      this.record.ws_id = NValue;
      this.record.ws_name = this.ws_name;
      // console.log("this.record", this.record);
      deep: true;
      immediate: true;
    },
  },
  methods: {
    RecordSon(data) {
      // this.record = data;
      this.ws_id = data.ws_id;
      this.ws_name = data.ws_name;
      console.log(data.ws_id);
    },
    // 获取当前时间
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    // 退出按钮
    TuiChu() {
      this.$router.push({
        // path: "/DailyMonitor",
        // name: "DailyMonitor",
        path: "/SiteList",
      });
    },
    // 分公司下拉框数据
    GetCompanies() {
      GetCompanies().then((res) => {
        this.selectOption.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.selectOption.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }

        // console.log(this.selectOption);
      });
    },
    // 选择的分公司下拉框的数据
    GetCompaniesValue() {
      GetCompanies().then((res) => {
        // console.log(res);
        this.queryParam.length = 0;
        this.queryParam.push({ value: " ", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.queryParam.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
        // console.log(this.queryParam);
      });
    },
    // 选中的下拉框数据
    handleChange(value) {
      this.bc_id = value.key;
      // console.log(this.bc_id);
    },
    afterVisibleChange(val) {
      // console.log("visible", val);
    },
    showDrawer(ref) {
      this.visible = true;
      if (ref == "SiteParticulars") {
        this.SiteParticulars = true;
        this.SitePreview = false;
        this.SitePlayback = false;
      } else if (ref == "SitePreview") {
        this.SitePreview = true;
        this.SiteParticulars = false;
        this.SitePlayback = false;
      } else if (ref == "SitePlayback") {
        this.SitePreview = false;
        this.SiteParticulars = false;
        this.SitePlayback = true;
      }
    },
    JumpSubpage() {
      this.SitePreview = false;
      this.SiteParticulars = false;
      this.SitePlayback = true;
    },
    onClose() {
      this.visible = false;
      this.SiteParticulars = false;
      this.SitePreview = false;
      this.SitePlayback = false;
      this.$refs.SitePreview.close();
    },
  },
};
</script>
<style lang="less" scoped>
.v-container {
  // background: #000934;
  background: #091423;
  min-width: 1200px;
  //   overflow: auto;
  width: 100%;
  height: 100%;
  // display: flex;
}
.sub-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 5%;
  //   line-height: 75px;
  //   line-height: 100%;
  //   display: flex;

  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;

  .v-title-left {
    position: absolute;
    left: 5%;
    color: #fff;
    font-size: 14px;
    // line-height: 60px;
    top: 25%;
  }
  //   分公司选择框
  .v-title-left2 {
    position: absolute;
    left: 20%;
    color: #fff;
    font-size: 14px;
    // line-height: 60px;
    // top: -8%;
    top: 20%;
  }
  .v-title-center {
    position: absolute;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    top: 20%;
    left: 46%;
  }
  //   .v-title-right {
  //     position: absolute;
  //     right: 5%;
  //     top: 0;
  //     color: #fff;
  //     font-size: 18px;
  //     // line-height: 60px;
  //     top: -8%;
  //     font-family: SimHei;
  //     font-weight: 400;
  //     color: #ffffff;
  //     cursor: pointer;
  //     &:hover {
  //       color: #66ffff;
  //     }
  //   }
}

.v-container-inner {
  position: relative;
  // width: 100%;
  width: 98%;
  height: 99%;
  // margin-top: 10px;
}

/deep/.ant-tooltip-inner {
  // background-color: #84929d;
  color: #ffffff;
  width: 170px;
}

// # 局部样式
.v-title-left2 /deep/ .ant-select {
  width: 100%;
  color: #ffffff;
  font-family: SimHei;
  font-size: 16px;
  font-weight: 400;
}
.v-title-left2/deep/ .ant-select-selection--single {
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: 400;
}
.v-title-left2 /deep/ .ant-select-selection__rendered {
  line-height: 36px;
}
.v-title-left2 /deep/ .ant-select-arrow {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.v-title-left2 /deep/ a-select-option {
  color: red;
}
.v-container-inner {
  display: flex;
  // justify-content: space-around;
  .container_left {
    width: 20%;
    height: 100%;
    // background-color: #fff;
    // div {
    //   width: 100%;
    //   height: 19%;
    //   // background-color: pink;
    //   border: solid 1px #66ffff;
    //   margin-bottom: 3%;
    // }
    .border {
      width: 100%;
      height: 19%;
      // background-color: pink;
      // border: solid 1px #66ffff;
      margin-bottom: 3%;
    }
    .Employee_attendance {
      height: 20%;
      // display: flex;
      // justify-content: center;
    }
    .Unmanned_construction {
      margin-bottom: none;
    }
  }
  .container_centre {
    width: 60%;
    height: 100%;
    // background-color: rgb(114, 168, 114);
    .container_centre_top {
      display: flex;
      width: 100%;
      height: 20%;
      justify-content: space-around;
      .border {
        width: 33%;
        height: 100%;
        // background-color: pink;
        // border: solid 1px #66ffff;
      }
    }
    .container_centre_map {
      width: 100%;
      height: 82%;
      // background-color: rgb(156, 199, 156);
      // margin-top: 1%;
    }
  }
  .container_right {
    width: 20%;
    height: 100%;
    // background-color: rgb(160, 126, 126);
    .border {
      width: 100%;
      // background-color: pink;
      // border: solid 1px #66ffff;
      margin-bottom: 2%;
    }
    .container_right_photo {
      height: 20%;
    }
    .container_right_count {
      height: 22%;
    }
    .container_right_smoke,
    .container_right_uniform {
      height: 28%;
    }
  }
}
.border {
  border: solid 1px #0c4170;
}
.v-container-data {
  height: 94%;
  width: 100%;
  display: flex;
  justify-content: center;
}
#v-container-img,
#v-container-Smoking {
  position: absolute;
  width: 10%;
  // height: 17%;
  height: 400px;
  bottom: 1%;
  left: 68%;
  overflow: hidden;
  z-index: 2;
}
#v-container-Smoking {
  left: 57%;
  // height: 150px;
  // height: 200px;
}
/deep/ .myVideo_out {
  width: 93% !important;
}
</style>
