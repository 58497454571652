<template>
  <!-- 新开工工地 -->
  <div class="employ_NewlySite">
    <div class="title">
      新绑定工地
      <a-tooltip
        placement="right"
        :get-popup-container="getPopupContainer"
        overlayClassName="popover"
      >
        <template slot="title">
          <!-- 截止昨天，最近7天每天录入的工地总数  -->
          统计昨日哪些工地进行了绑定操作，并且这些工地之前没有过绑定记录，去重工地，统计工地数量，即为新绑定工地数量；
        </template>
        <img
          src="../../assets/images/visualize/title_icon.png"
          style="padding: 0"
        />
      </a-tooltip>
    </div>
    <div id="NewlySite"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetNewonworksite, GetNewbindworksite } from "../../api/adminBoard";
export default {
  name: "NewlySite",
  props: {
    bc_id: {},
  },
  data() {
    return {
      option: {
        color: "#100C2A",
        tooltip: {
          trigger: "item",
          formatter: "{c}个", //{b}：name; {c}:value
        },
        grid: {
          // x: 20,
          // y: "4%",
          // right: "4%",
          // left: "16%",
          // bottom: "15%", //默认60
          // x: 20,
          y: "18%",
          right: "8%",
          left: "12%",
          bottom: "15%", //默认60
        },
        xAxis: {
          type: "category",
          data: ["A", "B", "C", "D", "E", "F", "G"],
          // name: "(日期)",
          boundaryGap: false, //坐标轴两边不留白
          nameTextStyle: {
            // x轴name的样式调整
            // color: "#000",
            fontSize: 12,
          },
          nameRotate: 90, // y轴name旋转90度 使其垂直
          nameGap: 5, // x轴name与横坐标轴线的间距
          axisLabel: {
            // interval: 0,
            // rotate: 20,
          },
        },
        yAxis: {
          type: "value",
          name: "工地数",
          nameGap: 10, // x轴name与横坐标轴线的间距
          min: 0,
          max: 300,
          position: "left",
          length: 7,
          splitLine: {
            lineStyle: {
              // 设置背景横线
              color: "#807a7a83",
            },
          },
        },
        series: [
          {
            data: [800, 1000, 900, 900, 1300, 1400, 1411],
            // data: [
            //   { value: 150, name: "date1" },
            //   { value: 240, name: "date2" },
            //   { value: 230, name: "date3" },
            //   { value: 150, name: "date4" },
            //   { value: 200, name: "date5" },
            //   { value: 210, name: "date6" },
            //   { value: 299, name: "date7" },
            // ],
            type: "line",
            color: "#386ABF",
            smooth: true, //将折线变成平滑的线条，不是直线
          },
        ],
      },
      queryParam: { bc_id: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    async loadData() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("NewlySite"));
      let xAxis_date = [];
      let sum = [];
      let resData = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      await GetNewbindworksite(params).then((res) => {
        // console.log("新绑定工地", res.data);
        resData = res.data;
        res.data.forEach((element) => {
          if (element.time) {
            let date = this.$Format(element.time, "date");
            sum.push(element.sum);
            xAxis_date.push(date.slice(5));
          }
        });
        // console.log(xAxis_date);
        this.option.series[0].data.sum = res.data.slice(1).sum;
        this.option.xAxis.data = xAxis_date;
        this.option.series[0].data = sum;
        // console.log(this.option.series[0].data);
        // 设置纵坐标的最大值
        let dataMax = [];
        res.data.forEach((el) => {
          dataMax.push(el.sum * 1);
        });
        function max(prev, next) {
          return Math.max(prev, next);
        }
        // console.log(dataMax.reduce(max));
        let max1 = dataMax.reduce(max) + "";
        // console.log(max1.slice(0, max1.length - 1));
        this.option.yAxis.max = max1.slice(0, max1.length - 1) * 10 + 10;
        // console.log(this.option.yAxis.max);
        // this.option.yAxis.min = Math.min.apply(null, dataMax) - 10;
      });

      // 绘制图表
      let vm = this;
      myChart.setOption(this.option);
      myChart.on("click", function (params) {
        console.log(params.name);
        resData.forEach((element) => {
          if (element.time) {
            let date = vm.$Format(element.time, "date").slice(5);
            // console.log(date);
            if (date == params.name) {
              let oneDay = 60 * 60 * 23;
              // console.log(element.time + oneDay);
              // console.log(vm.$Format(element.time));
              localStorage.setItem("path", "SiteList");
              vm.$router.push({
                path: "./SiteList",
                // name: "WorkerAttend",
                query: {
                  ws_bind_time_start: element.time,
                  ws_bind_time_end: element.time * 1 + oneDay,
                  ws_bind_status: "0", //绑定状态
                  ws_status: "0", //工地状态
                  d_status: "0", //设备状态
                },
              });
            }
          }
        });
        // console.log(params.dataIndex);
        // console.log(params.data);
        // if (params.componentType === "markPoint") {
        //   // 点击到了 markPoint 上

        //   if (params.seriesIndex === 5) {
        //     // 点击到了 index 为 5 的 series 的 markPoint 上。
        //   }
        // } else if (params.componentType === "series") {
        //   if (params.seriesType === "graph") {
        //     if (params.dataType === "edge") {
        //       // 点击到了 graph 的 edge（边）上。
        //     } else {
        //       // 点击到了 graph 的 node（节点）上。
        //     }
        //   }
        // }
      });
    },
  },
};
</script>

<style scoped lang="less">
.employ_NewlySite {
  height: 100%;
  width: 100%;
  // display: flex;
  // position: relative;
  .title {
    // position: absolute;
    width: 100%;
    height: 10%;
    color: #66ffff;

    align-items: center;
    padding-left: 10px;
    font-size: 18px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
#NewlySite {
  height: 90%;
  width: 100%;
  // top: -10%;
  // background-color: pink;
}
/deep/ .ant-tooltip-inner {
  width: 100% !important;
}
</style>
