<template>
  <!-- 员工出勤 -->
  <div class="employ_attendance">
    <div class="title">
      <!-- 员工出勤 -->
      7日出勤
      <a-tooltip
        placement="right"
        :get-popup-container="getPopupContainer"
        overlayClassName="popover"
      >
        <template slot="title">截止昨天，最近7天每天员工打卡总人数</template>
        <img
          src="../../assets/images/visualize/title_icon.png"
          style="padding: 0"
        />
      </a-tooltip>
    </div>
    <div id="attendance"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetWorkerattendance } from "../../api/adminBoard";

export default {
  name: "attendance",
  props: {
    bc_id: {},
  },
  data() {
    return {
      queryParam: { bc_id: "" },
      option: {
        color: "#100C2A",
        tooltip: {
          trigger: "item",
          formatter: "{c}人", //{b}：name; {c}:value
        },
        grid: {
          // x: 20,
          // y: "4%",
          // right: "4%",
          // left: "16%",
          // bottom: "15%", //默认60
          // x: 20,
          // y: "13%",
          y: "18%",
          right: "8%",
          left: "12%",
          bottom: "15%", //默认60
        },
        xAxis: {
          data: ["A", "B", "C", "D", "E", "F", "G"],
          // name: "(日期)",
          nameTextStyle: {
            // x轴name的样式调整
            // color: "#000",
            fontSize: 12,
          },
          // nameRotate: 40, // y轴name旋转90度 使其垂直
          nameGap: 5, // x轴name与横坐标轴线的间距
          // nameLocation: "middle", // x轴name处于x轴的什么位置
          boundaryGap: false, //坐标轴两边不留白
          axisLabel: {
            // interval: 0,
            // rotate: 20,
            textStyle: {
              // color: "#e33b38", //x轴字体颜色
            },
          },
          // axisLine: {
          //   lineStyle: {
          //     color: "#e33b38",
          //   },
          // },
        },
        yAxis: {
          // data: [0, 300, 600, 900, 1200, 1500],
          type: "value",
          name: "人数",
          nameGap: 10, // x轴name与横坐标轴线的间距
          // nameRotate: 50, // 因为是在rightMiddle 所以需要其翻转 将其改为负值
          // nameGap: -1240,  // 通过你生成的图表来调整
          // nameLocation: "middle", // y轴name处于y轴的什么位置
          min: 0,
          // max: 1500,
          max: 30,
          // position: "left",
          axisTick: {
            // show: true, //是否显示坐标轴刻度。
            // alignWithLabel: false, //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
            interval: 0, //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
            inside: false, //坐标轴刻度是否朝内，默认朝外。
            // length: 500, //坐标轴刻度的长度。
            // lineStyle: mylineStyle,
            length: 5,
          },
          // splitLine: { show: false },
          // axisLine: {
          //   lineStyle: {
          //     color: "#e33b38",
          //   },
          // },
          splitLine: {
            lineStyle: {
              // 设置背景横线
              color: "#807a7a83",
            },
          },
        },

        series: [
          {
            data: [800, 1000, 900, 900, 1300, 1400, 1411],
            // data: [
            //   { sum: 800, time: "06-23" },
            //   { sum: 1000, time: "06-24" },
            //   { sum: 900, time: "06-25" },
            //   { sum: 900, time: "06-26" },
            //   { sum: 1300, time: "06-27" },
            //   { sum: 1400, time: "06-28" },
            //   { sum: 1411, time: "06-29" },
            // ],
            type: "line",
            areaStyle: {
              // color: "#386ABF", //填充颜色
            },
            color: "#386ABF", //设置折线图中折线的颜色
            // symbolSize:x轴与y轴拐点的圆圈的大小
            // symbol:'star' //设置拐点的样式，star是正方形
            // smooth: true, //将折线变成平滑的线条，不是直线
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    async loadData() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("attendance"));
      let xAxis_date = [];
      let sum = [];
      let resData = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetWorkerattendance(params)
        .then((res) => {
          resData = res.data;
          // console.log(resData);
          res.data.forEach((element) => {
            if (element.time) {
              let date = this.$Format(element.time, "date");
              sum.push(element.sum);
              xAxis_date.push(date.slice(5));
            }
          });
          // console.log(xAxis_date);
          // this.option.series[0].data.sum = res.data.slice(1).sum;
          this.option.xAxis.data = xAxis_date;
          // console.log(res.data.slice(1));
          // 设置纵坐标的最大值
          let dataMax = [];
          res.data.slice(1).forEach((el) => {
            dataMax.push(el.sum * 1);
          });
          function max(prev, next) {
            return Math.max(prev, next);
          }
          // console.log(dataMax.reduce(max));
          let max1 = dataMax.reduce(max) + "";
          // console.log(max1.slice(0, max1.length - 1));
          this.option.yAxis.max = max1.slice(0, max1.length - 1) * 10 + 10;
          // console.log(this.option.series[0].data);
          this.option.series[0].data = sum;
        })
        .then();
      // 绘制图表
      let vm = this;
      myChart.setOption(this.option),
        myChart.on("click", function (params) {
          console.log(params.name);
          // console.log(params.dataIndex);
          // console.log(params.data);
          // console.log("resData", resData);
          resData.forEach((element) => {
            if (element.time) {
              let date = vm.$Format(element.time, "date").slice(5);
              // console.log(date);
              if (date == params.name) {
                let oneDay = 60 * 60 * 23;
                // console.log(element.time + oneDay);
                // console.log(vm.$Format(element.time));
                localStorage.setItem("path", "WorkerAttend");
                vm.$router.push({
                  path: "./WorkerAttend",
                  // name: "WorkerAttend",
                  query: {
                    start_time: element.time,
                    end_time: element.time * 1 + oneDay,
                  },
                });
              }
            }
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
.employ_attendance {
  height: 100%;
  width: 100%;
  // display: flex;
  // position: relative;
  .title {
    // background-color: #fff;
    // position: absolute;
    width: 100%;
    height: 10%;
    color: #66ffff;
    // text-align: left;
    // text-align: center;
    // display: flex;
    align-items: center;
    // margin-left: 30%;
    padding-left: 10px;
    font-size: 18px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
#attendance {
  height: 90%;
  // height: 99%;
  // padding-top: 10%;
  width: 100%;
}
/deep/ .ant-tooltip-inner {
  width: 100% !important;
  // background-color: #c9c9c9;
}
</style>
