<template>
  <!-- 连续无人施工天数 -->
  <div class="unmannedDates">
    <div class="title">
      <div class="title_days">
        连续无人施工
        <a-tooltip
          placement="right"
          :get-popup-container="getPopupContainer"
          overlayClassName="popover"
        >
          <template slot="title">绑定设备后连续几天无人打卡</template>
          <img
            src="../../assets/images/visualize/title_icon.png"
            style="padding: 0"
          />
        </a-tooltip>
      </div>
      <div class="title_more" @click="goSiteList">更多></div>
    </div>
    <div id="unmannedDates"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetLinknoworksite } from "../../api/adminBoard";
export default {
  name: "unmannedDates",
  props: {
    bc_id: {},
  },
  data() {
    return {
      option: {
        color: "#100C2A",
        tooltip: {
          trigger: "item",
          formatter: "{c}个工地", //{b}：name; {c}:value
        },
        grid: {
          // x: 20,
          // y: "4%",
          // right: "4%",
          // left: "16%",
          // bottom: "15%", //默认60
          x: 20,
          y: "18%",
          right: "8%",
          left: "12%",
          bottom: "15%", //默认60
        },
        xAxis: {
          type: "category",
          data: ["1", "2", "3", "4", "5", "6", "7+"],
          name: "天",
          nameTextStyle: {
            // x轴name的样式调整
            // color: "#000",
            fontSize: 12,
          },
          // boundaryGap: false, //坐标轴两边不留白
          // nameRotate: 90, // y轴name旋转90度 使其垂直
          nameGap: 5, // x轴name与横坐标轴线的间距
          // nameLocation: "middle", // x轴name处于x轴的什么位置
          // axisLabel: {
          //   interval: 0,
          //   rotate: 20,
          // },
        },
        yAxis: {
          type: "value",
          name: "工地数",
          nameGap: 10, // x轴name与横坐标轴线的间距
          min: 0,
          max: 200,
          position: "left",
          length: 7,
          splitLine: {
            lineStyle: {
              // 设置背景横线
              color: "#807a7a83",
            },
          },
        },
        series: [
          {
            data: [800, 1000, 900, 900, 1300, 1400, 1411],
            // data: [
            //   { value: 150, name: "date1" },
            //   { value: 240, name: "date2" },
            //   { value: 230, name: "date3" },
            //   { value: 150, name: "date4" },
            //   { value: 130, name: "date5" },
            //   { value: 122, name: "date6" },
            //   { value: 199, name: "date7" },
            // ],
            type: "bar",
            color: "#386ABF",
            smooth: true, //将折线变成平滑的线条，不是直线
            label: {
              show: true, //内部显示数值
              position: "inside", // outside外部显示  inside 内部显示
              formatter: `{c}`,
            },
          },
        ],
      },
      queryData: {
        ws_bind_status: "0", //绑定状态
        ws_status: "0", //工地状态
        d_status: "0", //设备状态
        nowork: "0", //无人施工天数
      },
      queryParam: { bc_id: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    // 跳转到工地列表页面
    goSiteList() {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "./SiteList",
        query: this.queryData,
      });
    },
    async loadData() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("unmannedDates"));
      let xAxis_date = [];
      let sum = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetLinknoworksite(params).then((res) => {
        // console.log(res.data);
        res.data.forEach((element) => {
          if (element.key) {
            let date = element.key;
            sum.push(element.sum);
            xAxis_date.push(date);
          }
        });
        // console.log(xAxis_date);
        this.option.series[0].data.sum = res.data.slice(1).sum;
        // this.option.xAxis.data = xAxis_date;
        this.option.series[0].data = sum;
        // console.log(this.option.xAxis.data);
        // console.log(this.option.series[0].data);
        // 设置纵坐标的最大值
        let dataMax = [];
        res.data.forEach((el) => {
          dataMax.push(el.sum * 1);
        });
        function max(prev, next) {
          return Math.max(prev, next);
        }
        // console.log(dataMax.reduce(max));
        let max1 = dataMax.reduce(max) + "";
        // console.log(max1.slice(0, max1.length - 1));
        this.option.yAxis.max = max1.slice(0, max1.length - 1) * 10 + 10;
        // console.log(this.option.yAxis.max);
      });

      // 绘制图表
      myChart.setOption(this.option);
      myChart.on("click", function (params) {
        console.log(params);
        console.log(params.dataIndex);
        console.log(params.data);
        // if (params.componentType === "markPoint") {
        //   // 点击到了 markPoint 上

        //   if (params.seriesIndex === 5) {
        //     // 点击到了 index 为 5 的 series 的 markPoint 上。
        //   }
        // } else if (params.componentType === "series") {
        //   if (params.seriesType === "graph") {
        //     if (params.dataType === "edge") {
        //       // 点击到了 graph 的 edge（边）上。
        //     } else {
        //       // 点击到了 graph 的 node（节点）上。
        //     }
        //   }
        // }
      });
    },
  },
};
</script>

<style scoped lang="less">
.unmannedDates {
  height: 100%;
  width: 100%;
  /deep/ div > div > canvas {
    cursor: default !important;
  }
  // display: flex;
  // position: relative;
  // justify-content: flex-end; //右对齐
  .title {
    width: 65%;
    height: 14%;
    // position: absolute;
    color: #66ffff;
    // text-align: left;
    // padding-left: 10px;
    font-size: 18px;
    margin-left: 35%;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 60%;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .title_more {
      margin-right: 10px;
      color: #1a6fff;
      cursor: pointer;
      border-bottom: #1a6fff 1px solid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }
}
#unmannedDates {
  height: 86%;
  width: 100%;
  // top: -10%;
  // background-color: pink;
}
/deep/ .ant-tooltip-inner {
  width: 100% !important;
}
</style>
